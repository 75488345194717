
.share-button{
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
}

.share-button{
    background: var(--color1);
    color: var(--blanco);
}


@media (max-width: 900px) {
  

   
}