.HeaderDashContain{
    background-color: var(--blanco);
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.114);
    width: 100%;

    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.deFlexHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
 gap: 2rem;
}
.deFlexHeader .link{
  font-size: .9em;
  font-family: 'Poppins';
}
@media (max-width: 1024px){
  .HeaderDashContain{
    flex-direction: column;
    padding-top: 1rem;
  }
  .deFlexHeader{
 
    gap: 10px;
   padding: 10px;
   flex-wrap: wrap;
   justify-content: space-around;
   
  }
}
