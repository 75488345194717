#previevImagesLogoBanner{
    display: flex!important;
    flex-direction: column!important;
    position: relative!important;
    align-items: center!important;
    margin-top: 1rem!important;
    justify-content: center;
    align-items: center;
    width: 100%;
}
#previevImagesBanner{
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
#previevImagesLogo{
    width: 150px!important;;
    height: 150px!important;
    object-fit: cover!important;
    border-radius: 100px;
    position: relative!important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073)!important;
   
}


#previevImagesLogoIcon{
    width: 50px!important;;
    height: 50px!important;
    object-fit: cover!important;
    border-radius: 100px;
    position: relative!important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.073)!important;
    padding: 10px;
    cursor: pointer;
    background-color: var(--gris2);
    margin-top: -4rem;
    margin-left: 5rem;
}

.deFlexBtnTienda{
    display: flex;gap: 1rem;
    align-items: center;
}
.eliminarBtn{
    padding: 13px 13px;
    border-radius: 10px;
    border: none;
    background-color: var(--color2);
    color: var(--blanco);
    width: 20vh;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 1rem;
}
.sectiontextTienda{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.sectiontextTienda fieldset{
    width: 30%;
}
@media (max-width: 700px){
    #previevImagesBanner{
        width: 100%;
        height: 130px;
        object-fit: cover;
        border-radius: 10px;
        position: relative;
    }
    #previevImagesLogo{
        width: 90px!important;;
        height: 90px!important;
       
    }
    #previevImagesLogoIcon{
        width: 35px!important;;
        height: 35px!important;
        margin-top: -5rem;
        margin-left: 3rem;
       
    }
    .sectiontextTienda fieldset{
        width: 100%;
    }
}