.AuthContainer{
    padding: 60px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: var(--blanco);
    position: relative;
    overflow: hidden;
}

.sinCuenta{
    background-color: transparent;
    border: none;
   
    text-align: center;
    color: var(--text-color2);
    cursor: pointer;
    margin-top: 2rem;
    font-size: 17px;
}
.sinCuenta span{
    color: var(--color1);
    font-size: 18px;
    margin-left: 10px;
}


.bgLogin {
    background-color: var(--color1);
    width: 100%;
    height: 90vh; 
    margin-top: 10vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    clip-path: ellipse(75% 60% at 50% 100%);
}
@media (max-width: 900px){
    .bgLogin {
      clip-path: ellipse(100% 50% at 50% 90%);
     
}
}
