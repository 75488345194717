#cardServicioLoading{
    width: 23rem;
    height: 18.7rem;
    margin: 1rem 1rem ;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
}
#butonLoading{
    width: 8rem;
    height: 2rem;
    border-radius: 20px;
     background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
     background-size: 400% 400%;
     animation: gradientMove 1s linear infinite;
     margin: 1rem 1rem ;
}

@media (max-width: 1024px){
    #cardServicioLoading{
        width: 60%;
        margin: 1rem 10px 0 5px;
        padding: 8px;
        height: 13.4rem;
    }
    #butonLoading{
        width: 8rem;
        height: 2rem;
        margin: 1rem 10px 0 5px;
    }
}