.SinPermisos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;
    align-self: center;
    place-content: center;
    padding: 10px 10%;
    width: 100%;
    height: 90vh;
}
.SinPermisos h1{
    text-align: center;
    color: var(--text-color2);
    
}

.SinPermisos img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}