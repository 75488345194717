.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;  
}

.spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-color2);
}

.spinnerImage {
   width: 120px;
   object-fit: cover;
   animation: resize 0.7s ease-in-out infinite alternate;
  
}

@keyframes resize {
    0% { transform: scale(1); }
    100% { transform: scale(1.1); }
}

p {
    margin-top: 10px;
}
