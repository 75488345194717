.Cerrado{
    background-color: var(--color1);
    padding: 6px 10px;
    border-radius: 7px;
    color: var(--blanco);
    font-size: .9em;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}

.Abierto{
    background-color: #008000;
    padding: 6px 10px;
    border-radius: 7px;
    color: var(--blanco);
    font-size: .9em;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}