
.table-container {
    max-height: 60vh;  
    overflow: auto;
    margin-top: 1rem;
}
.table-container::-webkit-scrollbar{
    width: 5px;
    height: 5px;
 
    cursor: pointer;
    background-color: transparent;
   
}
 
.table-container::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }

  
  
.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;
  
}

.table th, .table td {
    padding: 8px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color2);
}

.table th {
    background-color: #6868682b;
}

.table tr:nth-child(even) {
    background-color: #f2f2f261; /* Color para filas pares */
}

.table tr:nth-child(odd) {
    background-color: var(--blanco); /* Color para filas impares */
}

.table tr:hover {
    background-color: #f2f2f261; /* Color al hacer hover */
}


.noResult {
    display:flexbox;
    width: 20rem;
    height:10px ;
    border-radius: 7px;
    animation: gradientMove 1s linear infinite;
   
}

.table img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 100%;
 
}

.imgNonetd{
    width: 35px;
    height: 35px;
    border-radius: 4px;
   
    
}

.modal {
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
}
.modalImg{
    display: flex;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
}
.modal-contentImg{
    background-color: var(--text-color);
   
    padding: 0px;
   
    width: 120vh;
    height: 80vh;
    border-radius: 10px;
    overflow: hidden;
    
}
.modal-contentImg img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}
.modal-content {
    background-color: #fefefe;
   
    padding: 20px;
    border: 1px solid #888;
    width: 120vh;
    height: 80vh;
    border-radius: 10px;
    overflow-y: scroll;
    
}
.modal-content::-webkit-scrollbar{
    width: 5px;
    height: 5px;
 
    cursor: pointer;
    background-color: transparent;
   
}
 
.modal-content::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }
.close{
    font-size: 30px;
    text-align: right;
    cursor: pointer;
}
.close2{
    font-size: 30px;
    text-align: right;
    cursor: pointer;
    position: absolute;
    background-color: var(--blanco);
    border-radius: 10px;
    left: 75%;
    margin-top: 1rem;
    
}
.deFLexImg{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}
.deFLexImg img{
    min-width: 50vh;
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    object-fit: cover;
    margin-top: 10px;
    cursor: pointer;
}
.modal-content p{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--gris);
    border-radius: 10px;
}
.modal-content strong{
   
    color: var(--text-color2);
}
.deColumnGuardar{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    background-color: var(--gris);
    border-radius: 10px;
    margin-top: 10px;
}
.deColumnGuardar select{
    padding: 10px;
    border-radius: 7px;
 
}
.deColumnGuardar button{
    padding: 10px;
    border-radius: 7px;
    border: none;
    background-color: var(--color1);
    color: var(--blanco);
    cursor: pointer;
}
button{
    cursor: pointer;
}
.deFLex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
}
.deFLex h2{
    color: var(--text-color2);
}
.editar{
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: var(--color2);
    margin-left: 20px;
}
.eliminar{
    background-color: transparent;
    border: none;
    font-size: 20px;
    color: var(--color1);
}
.filtrosContain{
    display: flex;
  
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
    
}
.filtrosContain::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}
 
.filtrosContain::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    padding-bottom: 10px;
  }

.inputsColumn{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.inputsColumn input{
    border-radius: 7px;
    padding: 10px 10px;
    border: 1px solid #949191;
    width: 16vh;
    font-size: .85em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  
  
}
.inputsColumn select{
    border-radius: 7px;
    padding: 10px 10px;
    border: 1px solid #949191;
    width: 18vh;
    font-size: .85em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  
}
.inputsColumn button{
    border-radius: 7px;
    padding: 10px 10px;
    border: 1px solid #949191;
    width: 20vh;
    background-color: transparent;
    font-size: .85em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  
  
}
.inputsColumn .length{
    border-radius: 7px;
    padding: 10px 13px;
    border: 1px solid #949191;
    width: 15vh;
    background-color: transparent;
    font-size: .85em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  
}
#show-more-btn{
    background-color: var(--color1);
    color: var(--blanco);
    border: none;
    padding: 8px 15px;
    border-radius: 8px;
    margin-top: 1rem;
}
.excel{
        background-color: green;
        color: var(--blanco);
        border-radius: 7px;
        padding: 7px 13px;
        border: none;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: .9em;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
.pdf{
    padding: 6px 13px;
    background-color: red;
    color: var(--blanco);
    border-radius: 7px;
    border: none;
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: .9em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
.reverse{
  
    background-color: transparent;
    color: var(--text-color2);
    border-radius: 7px;
    border: none;
    border-radius: 7px;
    padding: 10px 13px;
    border: 1px solid #949191;
    display: flex;
    gap: 10px;
   
}
.reload{
    background-color: transparent;
    color: var(--text-color2);
    border-radius: 7px;
    border: none;
    border-radius: 7px;
    padding: 10px 13px;
    border: 1px solid #949191;
    
}
.deFlex2{
    display: flex;
    gap: 1rem;
}
.deFlexBtns{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding: 10px 0px;
    align-items: center;
}
.deFlexBtnsModal{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 10px 0px;
    align-items: center;
}
.deFlexBtnsModal button{
    background-color: transparent;
   border: none;
    padding: 5px 10px;
    color: var(--text-color2);
}
.selected{
    border-bottom: 2px solid var(--color1)!important;
    color: var(--color1)!important;
   
}
.previevProduct{
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
 
}
.previevProduct::-webkit-scrollbar{
    width: 5px;
    height: 5px;
 
    cursor: pointer;
    background-color: transparent;
   
}
 
.previevProduct::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }
.previevProduct{
   padding-bottom: 10px;
}
.previevProduct img{
    width: 150px;
    height: 150px;
   object-fit: cover;
   border-radius: 10px;
  
 
}
.imgNone{
    width: 150px;
    height: 150px;
  border-radius: 10px;
  background-color: var(--gris);
  color: var(--text-color2);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.sectiontext{
    display: flex;
   flex-direction: column;
    gap: 10px;
   
}
.sectionImg{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}

legend{
    padding: 10px;
    color: var(--text-color2);
}


fieldset {
    padding: 3px 4px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 49%;
    border: 1px solid #d1cccc;
}
fieldset input{
    padding: 3px ;
    width: 100%;
    border: none;
}
.sectiontext input{
    padding: 0px 10px 10px;
}
 textarea{
    width: 100%;
    min-height: 10vh;
    border: none;
    padding: 10px;
}
.sectiontext select{
    width: 100%;
    padding: 0px 10px 10px;
    border: none;
   
}
#descripcion{
    width: 100%;
}

#descripcion textarea{
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

}
.btnSave{
    padding: 7px 13px;
    border-radius: 10px;
    border: none;
    background-color: var(--color1);
    color: var(--blanco);
    width: 20vh;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    font-size: .9em;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
}
.btnPost{
    padding: 13px 13px;
    border-radius: 10px;
    border: none;
    background-color: var(--color1);
    color: var(--blanco);
    width: 20vh;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 1rem;
}
.btnLoading{
    padding: 13px 13px;
    border-radius: 10px;
    border: none;
    background-color: var(--loading);
    color: var(--blanco);
    width: 20vh;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 1rem;
}
.btnSave span{
    font-size: 20px;
    font-weight: bold;
}
.deFlexContent{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
    align-items: center;
    
}
@media (max-width: 1000px){
    .previevProduct img{
        width: 115px;
        height: 90px;
      
      }
      .imgNone{
        width: 100px;
        height: 100px;
        font-size: 12px;
    }
    .close2{
        left: 90%;
    }
    fieldset{
        width: 100%;
    }
    .inputsColumn input{
       
        width: 17vh;
      
    }
    .inputsColumn select{
       
        width: 17vh;
    }
    .filtrosContain{
        display: flex;
        flex-direction: row;
        
        gap: 1rem;
        overflow-x: scroll;

    }
    .filtrosContain::-webkit-scrollbar-thumb {
        background-color: transparent; 
        border-radius: 10px;
        padding-bottom: 10px;
      }
    .deFLexImg img{
        min-width: 17vh;
        width: 17vh;
        height: 17vh;
       
    }
    .modal {
        align-items:flex-end;
        justify-content:flex-end;
    }
    .modal-contentImg{
      
        width: 100%;
        height: 100vh;
        border-radius: 0;
        overflow: hidden;
        
}
    .modal-content {
       
       
        padding: 10px;
       
        width: 100%;
        height: 90vh;
        
        
    }
    .table-container {
       
        overflow-x: scroll;
    }
    .table {
        width: 100%;
        border-collapse:unset;
        table-layout: auto;
        text-align: none;
        
      }
      .deFlexBtns{
       
        gap: 10px;
     
       
    }
    .deFlex2{
        gap: 10px;
        overflow-x: scroll;
    }
    .deFlex2::-webkit-scrollbar{
        width: 5px;
        height: 0;
        cursor: pointer;
        background-color: transparent;
       
    }
     
    .deFlex2::-webkit-scrollbar-thumb {
        background-color:transparent; 
      }
    
    .deFlexContent{
    flex-wrap: nowrap;
    gap: 1rem;
    flex-direction: column;
    align-items:normal;
    }
}