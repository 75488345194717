.detail-contain{
    width: 100%;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    border-radius:15px ;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.955) 0%, rgb(255, 255, 255) 100%);
    overflow: hidden;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.071);
}
.detail{
    width: 100%;
    padding: 5% 12%;
    gap: 2rem;
    min-height: 100vh;
    position: relative;
    background-color: var(--blanco);
 
}


.imgContain{
    width: 100%;
    height: 70vh;
    position: relative;


}


.imgContain img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imgContain::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(255, 255, 255, 0) 80%); 
    z-index: 1;
}

.imgContain img {
    position: relative;
    z-index: 0;
}

.imgDetail{
    display: flex;
    gap: 1rem;

}



.custom-modal {
    background: rgba(0, 0, 0, 0.8);
   
  }
  
  .custom-modal img {
    width: 100%;
    height: 100%;
 
  }
  
  iframe {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
   
}
hr{
  
    border: 0.3px solid #00000013;
    
}

.share{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}
.favoritos-btn{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}

.deFlexDetail{
    display: flex;
    justify-content: space-between;
    padding: 0px 1%;
    align-items: center;
    gap: 2rem;
    position: absolute;
    z-index: 2;
    width: 76%;
}
.deFLexIcon{
    display: flex;
    justify-content: space-between;
    padding: 20px 0%;
    align-items: center;
    gap: 2rem;
}
.title {
    font-size: 1.7em;
    color: var(--text-color2);
    font-family: 'Poppins';
    font-weight: 600;
}

.detail-contain span{
    color: var(--text-color2);
    white-space: pre-line;
    font-size: 0.9em;
    font-family: 'Poppins';

}
.detail-contain button span{
    color: var(--blanco);
   
}
.detail-contain a span{
    color: var(--blanco);
   
}
.deColumn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.price {
    font-size: 1.3em;
    color: var(--color1);
    margin-top: 1rem;
  
}
.precioTachadoDetail {
    text-decoration: line-through;
    color: var(--text-color2)!important;
    font-size: 1.3em;
    margin-top: 1rem;
    opacity: .6;

  }
.titlecoment{
    color: var(--text-color2);
  
}

.wpp{
   background-color: rgb(30, 184, 30);
   color: var(--blanco);
   padding: 7px 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 13px;
   cursor: pointer;
   border-radius: 10px;
   gap: 10px;
   width: 7rem;
   
}
.emailBtn{
    background-color: var(--color1);
    color: var(--blanco);
    padding: 7px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 13px;
    cursor: pointer;
    border-radius: 10px;
    gap: 10px;
    width: 7rem;
}
.deFlexBtnDetail{
    display: flex;
    align-items: center;
    gap: 10px;
}
.wpp img{
    width: 25px;
}
.textDetail{
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    width: 100%;
    padding:  10px 20px;
    z-index: 1;
}
.back{
    background-color: var(--gris);
    border: none;
    padding: 7px 9px;
    border-radius: 100%;
    color: var(--text-color2);
    font-size: 17px;
   
}
.textDetail h4{
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--text-color2);
    font-weight: 500;
    font-size: 0.9em;
    font-family: 'Poppins';
}
.textDetail h5{
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--text-color2);
    font-weight: 500;
    font-size: 0.7em;
    font-family: 'Poppins';
}
.deFLexBuet{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.deFLexBuet h4{
    color: var(--color1);
    background-color: var(--color3);
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 13px;
}
.custom-description-modal{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
.custom-description-modal h2{
    font-size: 1.7;
    color: var(--text-color2);
}
.custom-description-modal span{
    white-space: pre-line;
    font-size: 0.9em;
    color: var(--text-color2);
}
.custom-description-modal button{
   cursor: pointer;
}
.view-more-btn{
    color: var(--color1)!important;
}
.userProfile{
    display: flex;
    gap: 10px;
    align-items: center;
}
.iconUser{
    background-color: var(--color1);
    color: var(--blanco);
    padding: 10px;
    border-radius: 100%;
}
.deFlexContentDetail{
    display: flex;
    justify-content: space-between;
}
@media (max-width: 1024px) {
    .fixedHeader{
        position: fixed!important;
        background-color: var(--blanco);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
        padding: 00px 4%;
        height: 3.3rem;
    }
    .deFLexIcon{
        gap: 1rem;
    }
    
    .detail-contain p{
        max-height: 100%;
        min-height:  100%;
    }
    .deFlexDetail{
        position: absolute ;
        z-index: 3;
        top: 0;
        width: 100%;
        padding: 0 20px;
    }
   
   
   
    .detail{
       
        padding:   0;
    }
    .detail-contain{
        flex-direction: column;
        padding:  0 0 100px 0 ;
        border-radius: 0;
        gap: 0;
    }
    .textDetail{
       padding: 20px;
       margin-top: -2rem;
       background-color: var(--blanco);
       border-radius:20px 20px 0 0;
       gap: 1rem;
}
.title {
    font-size: 1.2em;
}
.textDetail h4{
    
    font-size: 0.8em;

}


.wpp{
    padding:  7px;
     font-size: 9px;
     width: 1.6rem;
     border-radius:100% ;
   
}
 .emailBtn{
    padding:  7px;
    font-size: 9px;
    width: 1.6rem;
    border-radius:100% ;
 }
 .imgContain{
    height: 40vh;
}
.deFlexBtnDetail span{
    display: none;
}


.textDetail h5{
    font-size: 10px;
}
.textDetail h4{
    font-size: 11px;
}
.deFLexBuet h4{
    padding: 3px 7px;
    font-size: 10px;
}
.iconUser{
    padding: 8px;
    font-size: 10px;
}
.detail-contain span{
    font-size: 10px;
}
}
