.cardBanner img{
width: 100%;
height: 100%;
object-fit: cover;
}
.cardBanner{
    border-radius: 10px;
    width: 240px;
    height: 130px;
    overflow: hidden;
    background-color: var(--gris);
  position: relative;
}
.BannerContainer{
display: flex;
flex-direction: column;
gap: 2rem;
}
.BannerWrap{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    max-height: 60vh;  
    overflow: auto;
   
}
.spanBanner{
    position: absolute;
    top: 0;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    background-color: var(--blanco);
    left: 8%;
    padding: 5px 10px;
    font-size: 0.8em;
    border-radius: 2px;
}
.btnsBanner{
    position: absolute;
    top: 0;
    margin-top: 10px;
    display: flex;
    gap: 10px;
    left: 70%;
}
.btnBannerDelete{
    background-color: var(--blanco);
    color: var(--color1);
    border: none;
    font-size: 16px;
    padding: 4px;
    border-radius: 2px;
    
}
.btnBannerEdit{
    background-color: var(--blanco);
    color: var(--color2);
    border: none;
    font-size: 16px;
    padding: 4px;
    border-radius: 2px;

}

.BannerWrap::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}

 
.BannerWrap::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }

  @media (max-width: 1024px){
    .cardBanner{
        border-radius: 10px;
        width: 160px;
        height: 100px;
       
    }
    .BannerWrap{
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        max-height: 60vh;  
        overflow: auto;
        justify-content: space-between;
       
    }
    .spanBanner{
        left: 5%;
        padding: 5px 8px;
        font-size: 0.7em;
    }
    .btnsBanner{
        
        left: 60%;
    }
  }