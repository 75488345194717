#swiper_container {
    width: 100%;
    height: 65vh;
    border-radius: 20px;
}

#SwiperSlide-scroll {
    display: flex;
    justify-content: center;
}

#SwiperSlide-scroll img {
    width: 100%;
    object-fit: cover;
}

.loadingBanner {
    width: 100%;
    height: 65vh;
    border-radius: 20px;
    background: linear-gradient(90deg, #e8e6e6, #fff, #fff);
    background-size: 400% 400%;
    animation: gradientMove 1s linear infinite;
    -webkit-animation: gradientMove 1s linear infinite;
}

.BannerContain {
    padding: 0% 10%;
    padding-top: 5rem;
    background-color: var(--blanco);
}

.service-slide {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
}

.service-slide-text {
    background-color: rgba(0, 0, 0, 0.29);
    backdrop-filter: blur(5px);
    position: absolute;
    margin: 2rem 4rem;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 50vh;
}

.deFlexSlide {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.deColumnlide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.deFlexSlide img {
    width: 6em!important;
    height: 6em;
    border-radius: 10px;
}

.service-slide-text a {
    background-color: var(--color1);
    padding: 10px;
    border-radius: 10rem;
    color: var(--blanco);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    -webkit-border-radius: 10rem;
    -moz-border-radius: 10rem;
    -ms-border-radius: 10rem;
    -o-border-radius: 10rem;
}

.service-slide-text h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
    font-weight: 500;
    font-size: 19px;
    color: var(--blanco);
}
.service-slide-text span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 40ch;
    font-weight: 500;
    font-size: 13px;
    color: var(--blanco);
}
.service-slide-text h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    font-weight: 500;
    font-size: 25px;
    color: var(--blanco);
}

.service-slide-text strong {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
    font-weight: 400;
    font-size: 17px;
    color: var(--blanco);
}

@media (max-width: 900px) {
    .BannerContain {
        padding: 0% 3%;
        padding-top: 1rem;
    }

    #swiper_container {
        height: 22vh;
        border-radius: 20px;
    }

    .loadingBanner {
        height: 22vh;
        border-radius: 20px;
    }

    .deFlexSlide img {
        width: 2em!important;
        height: 2em;
        border-radius: 4px;
    }

    .service-slide-text h3 {
        font-size: 13px;
    }

    .service-slide-text h5 {
        font-size: 14px;
    }

    .service-slide-text strong {
        max-width: 20ch;
        font-size: 13px;
    }

    .service-slide-text {
        position: absolute;
        margin: 1rem 1rem;
        border-radius: 10px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-width: 15vh;
    }

    .deColumnlide {
        gap: 1px;
    }

    .service-slide-text a {
        padding: 3px;
        width: 6rem;
        font-size: 11px;
    }
    .service-slide-text span {
        max-width: 25ch;
        font-size: 11px;
    }

}
