.profileContain{
    padding: 10px 5%;
    background-color: var(--blanco);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 9900000000!important;
    justify-content: center;
   
    
}
.profileContain img{
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 100%;
    margin-top: -3rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.127);
    background-color: var(--blanco);
}
.profileContain h2{
    font-weight: 500;
    font-size: 1.2em;
    color: var(--text-color2);
    margin-top: 10px;
}
.socials{
    display: flex;
    gap: 2rem;
}
.profileContain .socials a{
    color: var(--text-color2);
    font-size: 14px;
}
.profileText{
    display: flex;
    flex-direction: column;
    gap: 10px;
    
}
.profileText2{
    display: flex;
    flex-direction: column;
    gap: 0;
    border-bottom: 1px solid var(--gris2);

}

.profileText::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}
.profileText::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }
.profileText a{
    color: var(--text-color2);
    padding-bottom: 10px;
    font-size: 0.8em;
}
.profileText p{
    color: var(--text-color2);
    padding-bottom: 10px;
    font-size: 0.8em;
}
@media (max-width: 700px) {
    .profileContain{
        padding: 10px 7%;
       
    }
   
}