#Servicios{
    padding: 30px  10%;
    background-color: var(--blanco);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}
.categoriaSection{
    display: flex;
    flex-direction: column;
}
#cardsServicio{
    
    width: 100%;
}
#cardServicio{
    width: 23rem;
    height: 18.7rem;
    background-color: var(--blanco);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.107);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 20px;
    margin: 0rem 1rem 2rem 1rem;
    z-index: 2;
    padding: 10px;
   
}
#cardServicio:hover{
    background-color: var(--color1);
}
#cardServicio:hover h3{
    color: var(--blanco);
}
#cardServicio:hover span{
    color: var(--blanco);
}
#cardServicio:hover strong{
    color: var(--blanco);
}
#cardServicio:hover .iconCard{
    color: var(--color1);
    background-color: var(--blanco)!important;
}
#cardServicio img{
    width: 100%;
    height: 11rem;
    object-fit: cover;
    border-radius: 20px;
}

.cardServicioText{
    display: flex;
    flex-direction: column;
    gap: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 10px;
}

.cardServicioText span {
    font-size: 0.8em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 45ch;
    color: var(--text-color2);
    font-family: 'Poppins';
}
.cardServicioText h3{
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 28ch;
    font-weight: 500;
    color: var(--text-color2);
    font-family: 'Poppins';
}
.cardServicioText strong{
    font-size: 1em;
    color: var(--color1);
    font-family: 'Poppins';
    font-weight: 600;
}
.categoriaSection h2{
    font-weight: 600;
    color: var(--color1);
    border-bottom: 1px solid #4140403d;
    margin-bottom: 2rem;
    margin-top: 2rem;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: 'Poppins';
    font-weight: 500;
}
.iconCardh2{
    font-size: 15px;
}
.deFlexCard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;
}
.iconCard{
    background-color: var(--color1);
    color: var(--blanco);
    padding: 3px;
    border-radius: 3px;
    font-size: 12px;
}

.filtrosTipos {
    display: flex;
    gap: 10px;
    background-color: var(--gris2);
    display: flex;
    justify-content: center;
    place-content: center;
    border-radius: 10rem;
    margin: 0 30%;
    
}

.filtroBoton {
    padding: 10px 35px;
    border: none;
    color: var(--text-color2);
    cursor: pointer;
    border-radius: 10rem;
    font-size: 14px;
    width: 100%;
    font-family: 'Poppins';
}

.filtroBoton.activo {
    background-color: var(--color1);
    color: var(--blanco);
    width: 100%;
}
.subcategoria {
    font-size: 0.9rem;
    color: #555;
    margin-top: 5px;
}
.subcategoria{
    background-color: var(--color1);
    color: var(--blanco);
    padding: 5px 10px;
    font-size: 0.7em;
    border-radius: 10px 0 10px 0;
    position: absolute;
    z-index: 1;
    top: 0;
    margin: 0;
    
}

.serviciosLista {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
   
}

.cardServicio {
    width: 30%;
    margin-bottom: 20px;
}

.categoriaBoton{
    background-color: var(--gris2);
    color: var(--text-color2);
    padding: 10px 20px ;
    border-radius: 10rem;
    border: 1px solid var(--gris2);
    display: flex;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Poppins';
}
.activeCategoria {
    background-color: var(--color1);
    color: var(--blanco);
    padding: 10px 20px ;
    text-align: center;
    justify-content: center;
    cursor: pointer;
}
.filtrosCategorias{
    display: flex;
    align-items: center;
    gap: 1rem;
    overflow-x: scroll;
    width: 100%;
    margin-top: 1rem;
}
.filtrosCategorias::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    cursor: pointer;
    background-color: transparent;
   
}

 
.filtrosCategorias::-webkit-scrollbar-thumb {
    background-color: var(--color1); 
    border-radius: 10px;
    margin-top: 3rem;
  }
@media (max-width: 1000px) {
    #Servicios{
        padding: 5px 0% 50px;
        z-index: 2;
        width: 100%;
}
#cardsServicio{
    padding-left: 10px;

}
#cardServicio{
    width: 60%;
    margin: 10px 10px 1rem 5px;
    padding: 0;
    height: 13.4rem;
    border-radius: 15px;
   
}
#cardServicio img{
    height:7rem;
    border-radius: 0;
    
}
.cardServicioText{
    padding: 8px 10px;
}
.cardServicioText h3{
    font-size: 1em;
  
}
.cardServicioText span {
    font-size: 0.7em;
    
}
.cardServicioText strong{
    font-size: 0.8em;
  
}
.iconCard{
    font-size: 8px;
}
.categoriaSection h2{
    margin: 10px 6%;

    width: auto;
    font-size: 1.1em;
}
.filtroBoton {
    padding: 8px 20px ;
   
}
.filtrosCategorias{
    margin-top: 0;
    gap: 10px;
    padding-left: 3%;
}

 
.filtrosCategorias::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .filtrosTipos{
    justify-content: space-around;
    margin: 3%;
    width: auto;
  }
  .serviciosLista {
    gap: 0;
    justify-content: center;
    padding: 1%;
    margin-top: 10px;
}

  .serviciosLista #cardServicio {
    width: 100%;
    height: auto;
    margin: 0 10px 1rem 5px;
    display: flex;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0);
    border-radius: 0;
    border-bottom: 1px solid var(--gris2);
}
.serviciosLista #cardServicio a{
    display: flex;
    gap: 7px;
    width: 100%;
}
.serviciosLista #cardServicio img{
    height:7rem;
    border-radius: 10px;
}
.serviciosLista .cardServicioText{
    justify-content: space-around;
}
.serviciosLista #cardServicio img{
    height: 6rem;
    width: 6rem;
}


.serviciosLista .cardServicioText span {
    max-width: 28ch;
}
.serviciosLista .cardServicioText h3{
    max-width: 20ch;
}
.serviciosLista .cardServicioText strong{
    max-width: 28ch;
}
.subcategoria {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
}
.serviciosLista .subcategoria {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 17ch;
    font-size: 9px;
}
.categoriaBoton{
    padding: 6px 18px ;
}
.activeCategoria {
    padding: 6px 18px ;
}
}